import React from 'react';
import '../../css/bestseller.css';
import c1 from '../../asset/c1.jpg';
import c2 from '../../asset/c2.jpg';
import c3 from '../../asset/c3.jpg';
import c4 from '../../asset/c4.jpg';
import c5 from '../../asset/c5.jpg';
import c6 from '../../asset/c6.jpg';
import c7 from '../../asset/c7.jpg';
import c8 from '../../asset/c8.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function BestSeller() {
    return (
        <section>
            <div className='main3'>
                <div className='main3-inner'>
                    <div className='main3-heading'>
                        <h1>FEATURED PRODUCTS</h1>
                    </div>
                    <div className='main3-grid-container'>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c1} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c2} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c3} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c4} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c5} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c6} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c7} effect="blur"/>
                        </div>
                        <div className='main3-grid-item'>
                            <LazyLoadImage className='main3-item' src={c8} effect="blur"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BestSeller;