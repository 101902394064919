import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

function Footer() {
    const [showContact, setShowContact] = useState(false);

    const toggleContact = () => {
        setShowContact(!showContact);
    };

    return (
        <div className='footer'>
            <div className="footer-content">
                <div className="footer-section about">
                    <h2>DARLIBABA</h2>
                    <p><Link to="/#">Terms of Use</Link></p><p className='divide'>|</p>
                    <p><Link to="/#">Privacy Policy</Link></p>
                </div>

                <div className="footer-section contact-web">
                        <div>
                            <p>BIGPIE C&T Co.,Ltd</p><p className='divide'>|</p>
                            <p>registration No : 169-81-00496</p><p className='divide'>|</p>
                            <p>Tel : +82-07-7702-1693</p><p className='divide'>|</p>
                            <p>FAX : +82-32-724-0688</p>
                        </div>
                        <div>
                            <p>#1820, Sambo Techno Tower 122, Jomaru-ro 385beon-gil, Bucheon-si, Gyeonggi-do, Republic of Korea</p>
                        </div>
                        <div>
                            <p>CEO : Ryan Lee</p><p className='divide'>|</p>
                            <p>mail order sales report : 제 2021-경기부천-1209호</p><p className='divide'>|</p>
                            <p>CPO : WESLEY HWANG</p><p className='divide'>|</p>
                            <p>mail: bigpie_global@jungdari.com</p>
                        </div>
                  </div>

                <div className="footer-section contact">
                    <button className="show-more" onClick={toggleContact}>
                        {showContact ? 'Show Less' : 'Show More'}
                    </button>
                    <div className={showContact ? "contact-content show" : "contact-content"}>
                        <div>
                            <p>BIGPIE C&T Co.,Ltd</p><p className='divide'>|</p>
                            <p>registration No : 169-81-00496</p><p className='divide'>|</p>
                            <p>Tel : +82-07-7702-1693</p><p className='divide'>|</p>
                            <p>FAX : +82-32-724-0688</p>
                        </div>
                        <div>
                            <p>#1820, Sambo Techno Tower 122, Jomaru-ro 385beon-gil, Bucheon-si, Gyeonggi-do, Republic of Korea</p>
                        </div>
                        <div>
                            <p>CEO : Ryan Lee</p><p className='divide'>|</p>
                            <p>mail order sales report : 제 2021-경기부천-1209호</p><p className='divide'>|</p>
                            <p>CPO : WESLEY HWANG</p><p className='divide'>|</p>
                            <p>mail: bigpie_global@jungdari.com</p>
                        </div>
                    </div>
                </div>
                <div className="footer-section copyright">
                    &copy; 2024 Your Company | All Rights Reserved
                </div>
            </div>
        </div>
    );
}

export default Footer;