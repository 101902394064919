import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { db } from '../firebase';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import emailjs from '@emailjs/browser';
import '../css/common.css';
import '../css/registerus.css'


function OemInquiry() {
  const navigate = useNavigate();

  const [targetCountry, setTargetCountry] = useState('');
  const [productType, setProductType] = useState('');
  const [volume, setVolume] = useState('');
  const [quantity, setQuantity] = useState('');
  const [features, setFeatures] = useState('');

  const [loading, setLoading] = useState(false);

  function formatTimestamp(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      if (!targetCountry || !productType || !quantity) {
        alert("Please enter required data.");
        setLoading(false); // 로딩 상태 해제
        return;
      }

      try {

        const today = new Date().toISOString().split('T')[0]; // 오늘 날짜 (YYYY-MM-DD 형식)
        // 현재 시간 포맷팅
        const timestamp = formatTimestamp(new Date());

        const auth = getAuth();
        const user = auth.currentUser;  // 현재 로그인된 사용자 가져오기
    
        if (!user) {
          alert("User is not logged in.");
          return;
        }
         // user 컬렉션에서 정보 가져오기
        const userDocRef = doc(db, "users", user.uid);  // user.uid는 현재 로그인된 사용자의 uid라고 가정
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {

          const userData = userDocSnap.data();  // user 컬렉션의 데이터
  
            // inquiry 컬렉션에 새로운 문서 추가
            await addDoc(collection(db, "inquiry"), {
              uid: user.uid,
              firstName: userData.firstName,       // user 컬렉션에서 가져온 데이터
              lastName: userData.lastName,
              countryCode: userData.countryCode,
              localPhoneNumber: userData.localPhoneNumber,
              email: userData.email,

              targetCountry: targetCountry,
              productType: productType,
              volume: volume,
              quantity: quantity,
              features: features,

              createdAt: serverTimestamp(),
              timestamp:timestamp
            });

            const templateParams = {
              firstName: userData.firstName,
              lastName: userData.lastName,
              countryCode: userData.countryCode,
              localPhoneNumber: userData.localPhoneNumber,
              email: userData.email,
              targetCountry: targetCountry,
              productType: productType,
              volume: volume,
              quantity: quantity,
              features: features,
              timestamp:timestamp
          };
          const emailResult = await emailjs.send('service_e6fxwyo', 'template_9jbh0se', templateParams, '-PYXUln711WX82nnA');
          console.log(emailResult.text); // 이메일 전송 성공 로그

          alert('Registration Successful.\nDarlibaba Manager will contact you ASAP.');
          navigate('/');
          } else {
            alert("User data not found.");
          }
        } catch (error) {
          alert('Invalid Info');
          console.log(error.message);
        } finally {
          setLoading(false); // Stop loading
        }
      };
      
  return (
    <>
    {loading && (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
)}

<div className='inquirybanner'>
    <div  style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>Inquiry</div>
</div>
<div style={{minHeight:"800px", display:"flex", backgroundColor:"#f4f4f4"}}>
<div className="register-us-container">

      {/* Product Info Section */}
      <div className="register-us-section">
        <h2 className="register-us-section-title">Product Info</h2>
        <form className="register-us-form">
          <div className="register-us-form-group">
            <label>Target Country<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={targetCountry}
                onChange={(e) => setTargetCountry(e.target.value)}
                placeholder="Enter target country" />
          </div>
          <div className="register-us-form-group">
            <label>Product Type<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                placeholder="ex. skincare, serum.." />
          </div>
          <div className="register-us-form-group">
            <label>Volume</label>
            <input 
                type="text"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                placeholder="ex. g, ml" />
          </div>
          <div className="register-us-form-group">
            <label>Quantity<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="How many units do you need?" />
          </div>
          <div className="register-us-form-group">
            <label>Other Features</label>
            <input 
                type="text"
                value={features}
                onChange={(e) => setFeatures(e.target.value)}
                placeholder="Describe other features" />
          </div>
        </form>
      </div>

      {/* Submit Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button className="register-us-submit-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
    </div>
    
   </>
  );
}

export default OemInquiry;