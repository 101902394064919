import React, { useState } from 'react';
import '../css/common.css';
import '../css/aboutus.css';
import op1 from '../asset/op1.png';
import op2 from '../asset/op2.png';
import op3 from '../asset/op3.png';
import op4 from '../asset/op4.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function OurProduct() {
  const [showAboutUs, setShowAboutUs] = useState(true);
  const [showAboutUss, setShowAboutUss] = useState(false);

  const handleShowAboutUs = () => {
    setShowAboutUs(true);
    setShowAboutUss(false);
  };

  const handleShowAboutUss = () => {
    setShowAboutUs(false);
    setShowAboutUss(true);
  };

  return (
    <>
      <div className='ourproductbanner'>
        <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>Our Product</div>
      </div>

      <div className="button-container">
        <button className="stylish-button ondo" onClick={handleShowAboutUs}>ONDO</button>
        <button className="stylish-button" onClick={handleShowAboutUss}>,b orinheal</button>
      </div>

      {showAboutUs && (
        <div className='aboutus'>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op1} effect="blur"/>
          </div>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op2} effect="blur"/>
          </div>
        </div>
      )}

      {showAboutUss && (
        <div className='aboutuss'>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op3} effect="blur"/>
          </div>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op4} effect="blur"/>
          </div>
        </div>
      )}
    </>
  );
}

export default OurProduct;