import React, { useState, useEffect } from 'react';
import { Parallax } from "react-parallax";
import '../css/common.css'
import Video from './mainContent/Video';
import Intro from './mainContent/Intro';
import OemList from './mainContent/OemList';
import BestSeller from './mainContent/BestSeller';
import Partner from './mainContent/Partner';
import popupImg from '../asset/cosmo.jpg'


function Main({isLoggedIn}) {
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [isPopupChecked, setIsPopupChecked] = useState(false);

  useEffect(() => {
      const popupCloseTime = localStorage.getItem('popupCloseTime');
      if (popupCloseTime) {
          const currentTime = new Date().getTime();
          const elapsedTime = currentTime - popupCloseTime;
          const twentyFourHours = 24 * 60 * 60 * 1000;

          if (elapsedTime < twentyFourHours) {
              setIsPopupVisible(false);
          }
      }
  }, []);

  const handleCheckboxChange = () => {
      setIsPopupChecked(!isPopupChecked);
  };

  const handleClose = () => {
      if (isPopupChecked) {
          const currentTime = new Date().getTime();
          localStorage.setItem('popupCloseTime', currentTime);
      }
      setIsPopupVisible(false);
  };


  return (
    <>
     {isPopupVisible && (
                <div className="popup-containerA">
                    <div className="popupA">
                        <div className="popupA-top">
                            <a href="/">
                                <img src={popupImg} alt="Popup" width={600}/>
                            </a>

                            <div className="popupA-top-left">
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={isPopupChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <div>&nbsp;&nbsp;Do not show again for 24 hours</div>
                                </div>
                            </div>

                            <div className="popupA-top-right">
                                <div className="close-btnA" onClick={handleClose}>
                                    &times;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

    <Parallax>
    <Video isLoggedIn={isLoggedIn}/>
    </Parallax>

    <Intro/>

    <OemList />

    <BestSeller />

    <Partner />

   </>
  );
}

export default Main;